<template>
  <b-card>
    <!-- media -->
    <!-- <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="generalData.avatar"
            height="80"
          />
        </b-link>
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif, .jpeg"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button>
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media> -->
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <!-- <b-col sm="6">
          <b-form-group label="Username" label-for="account-username">
            <b-form-input
              v-model="generalData.username"
              placeholder="Username"
              name="username"
            />
          </b-form-group>
        </b-col> -->
        <b-col sm="6">
          <b-form-group
            :label="$t('Full name')"
            label-for="account-name"
          >
            <b-form-input
              v-model="generalData.fullname"
              name="name"
              :placeholder="$t('Full name')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="generalData.email"
              readonly
              name="email"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col sm="6">
          <b-form-group label="Company" label-for="account-company">
            <b-form-input
              v-model="generalData.company"
              name="company"
              placeholder="Company name"
            />
          </b-form-group>
        </b-col> -->
        <b-col md="6">
          <b-form-group
            label-for="example-datepicker"
            :label="$t('Birth date')"
          >
            <flat-pickr
              v-model="generalData.birthday"
              class="form-control"
              name="date"
              :placeholder="$t('Birth date')"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="example-datepicker"
            :label="$t('Gender')"
          >
            <v-select
              v-if="GenderOptions"
              v-model="generalData.gender"
              label="title"
              :reduce="x => x.id"
              :options="GenderOptions"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col md="6">
          <b-form-group label-for="phone" label="Phone">
            <cleave
              id="phone1"
              v-model="generalData.phone"
              class="form-control"
              :raw="false"
              :options="clevePhone"
              placeholder="Phone number"
            />
          </b-form-group>
        </b-col> -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="handleSubmit()"
          >
            {{ $t('Save changes') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  // BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  // BAlert,
  BCard,
  // BCardText,
  // BMedia,
  // BMediaAside,
  // BMediaBody,
  // BLink,
  // BImg,
} from 'bootstrap-vue'
import Request from '@/plugins/service/index'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.vn'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BForm,
    // BImg,
    // BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    // BAlert,
    BCard,
    // BCardText,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    // BLink,
    flatPickr,
    // Cleave,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
      clevePhone: {
        phone: true,
        phoneRegionCode: 'VN',
      },
      GenderOptions: [
        {
          id: 0,
          title: 'Female',
        },
        {
          id: 1,
          title: 'Male',
        },
        {
          id: 2,
          title: 'Orther',
        },
      ],
    }
  },
  methods: {
    async handleSubmit() {
      try {
        const params = {}
        params.birthday = this.generalData.birthday
        params.fullname = this.generalData.fullname
        params.gender = this.generalData.gender
        const res = await Request.put(
          this.$http,
          `${process.env.VUE_APP_API_URL}/me/update`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Great',
                icon: 'AlertOctagonIcon',
                variant: 'success',
                text: 'Successful!',
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
