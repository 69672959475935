/* eslint-disable object-shorthand */
/* eslint-disable object-shorthand */
<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            :label="$t('Old Password')"
            label-for="account-old-password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="passwordValueOld"
                name="old-password"
                :type="passwordFieldTypeOld"
                :placeholder="$t('Old Password')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="account-new-password"
            :label="$t('New Password')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="newPasswordValue"
                :type="passwordFieldTypeNew"
                name="new-password"
                :placeholder="$t('New Password')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            :label="$t('Confirm password')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="RetypePassword"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                :placeholder="$t('Confirm password')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <span
            v-if="error"
            class="text-danger"
          >{{ errorText }}</span>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="handleSubmit()"
          >
            {{ $t('Save changes') }}
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      error: false,
      errorText: null,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      // eslint-disable-next-line operator-linebreak
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      // eslint-disable-next-line operator-linebreak
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      // eslint-disable-next-line operator-linebreak
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    async handleSubmit() {
      this.error = false
      if (this.RetypePassword === '' || this.newPasswordValue === '' || this.passwordValueOld === '') {
        this.errorText = 'Please enter all fields'
        this.error = true
      }
      if (this.RetypePassword !== this.newPasswordValue) {
        this.errorText = 'Confirm password is not correct'
        this.error = true
      }
      if (this.newPasswordValue.length <= 5) {
        this.errorText = 'Password must be more than 6 characters'
        this.error = true
      }
      if (this.error === false) {
        try {
          const params = {}
          params.old_password = this.passwordValueOld
          params.new_password = this.newPasswordValue
          params.confirm_password = this.RetypePassword
          const res = await Request.put(
            this.$http,
            `${process.env.VUE_APP_API_URL}/me/change_pass`,
            params,
          )
          if (res.status === 200) {
            if (res.data.status) {
              this.passwordValueOld = ''
              this.newPasswordValue = ''
              this.RetypePassword = ''
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Opps! Great',
                  icon: 'AlertOctagonIcon',
                  variant: 'success',
                  text: 'Successful!',
                },
              })
            } else {
              // eslint-disable-next-line array-callback-return
              Object.keys(res.data.error).map(strKey => {
                this.errorText = `${res.data.error[strKey]}`
              })
              this.error = true
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
    },
  },
}
</script>
