<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('General') }}</span>
      </template>

      <account-setting-general
        v-if="options"
        :general-data="options"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Change Password') }}</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- notification -->
    <!-- <b-tab>
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <account-setting-notification
        v-if="options.notification"
        :notification-data="options.notification"
      />
    </b-tab> -->
  </b-tabs>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
// import AccountSettingInformation from './AccountSettingInformation.vue'
// import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    // AccountSettingInformation,
    // AccountSettingNotification,
  },
  mixins: [general],
  data() {
    return {
      options: {
        fullname: null,
      },
    }
  },
  // beforeCreate() {
  //   this.$http.get('/account-setting/data').then(res => {
  //     this.options = res.data
  //   })
  // },
  created() {
    this.getProfile()
  },
  methods: {
    async getProfile() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/me/profile`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.options = res.data.data
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
